<template>
  <v-card class="elevation-0 mt-4 service bg-light section text-xs-center">

    <v-row class="mr-10 ml-10">
      <v-col
        cols="6"
        sm="6"
        md="6"
      >
        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Judul, Deskripsi Singkat (press ENTER to search)"
            label="Search"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-layout wrap justify-center="true">
      <v-card
          max-width="320"
          xs12 sm4 px-3
          class="mx-2 my-2 rounded-lg"
          v-for="(item, i) in fKurikulumFiltered"
          :key="i"
          elevation="10"
      >

        <v-img
            height="250"
            :lazy-src="lookupImageUrlLazy(item)"
            :src="lookupImageUrl(item)"
        >
          <v-card-title class="blue--text" v-if="false">Sekolah Dahlan Ikhsan</v-card-title>
        </v-img>

        <v-card-title>
          <div>
            {{item.description}}
          </div>
        </v-card-title>
        <v-card-subtitle>
          <div class="">
            <span class="green--text">
            {{ computedDateFormattedDatefns(item.fperiodeBeanRes.periodeFrom) }}
            </span>
            SD
            <span class="green--text">
              {{ computedDateFormattedDatefns(item.fperiodeBeanRes.periodeTo) }}
            </span>
          </div>

          <span>Kuota</span>
          <v-flex>
            <v-icon>mdi-human-male</v-icon> Laki-laki <span class="font-weight-bold">{{item.kuotaMale}}</span>
            <span class="caption ml-1" v-if="lookupFtKrs(item.id, true).length >0">
              (Pendaftar
              {{ lookupFtKrs(item.id, true).length}})
            </span>
          </v-flex>
          <v-flex>
            <v-icon color="pink">mdi-human-female</v-icon> Perempuan <span class="font-weight-bold">{{item.kuotaFemale}}</span>
            <span class="caption ml-1" v-if="lookupFtKrs(item.id, true).length >0">
              (Pendaftar
              {{ lookupFtKrs(item.id, false).length }})
            </span>
          </v-flex>
          <v-flex class="caption font-weight-thin d-flex justify-end">
            {{item.id}}
          </v-flex>
        </v-card-subtitle>

        <v-card-text>

          <v-divider></v-divider>
          <v-flex class="blue--text mb-4">Biaya {{ formattedCurrencyValue('Rp.', item.amountBiaya )}}</v-flex>

          <v-textarea  class="font-weight-light" dense hide-details auto-grow rows="2" readonly v-model="item.notesBiaya"></v-textarea>

          <div>Pendaftaran Buka</div>
          <span class="blue--text">
            {{ computedDateFormattedDatefns(item.fperiodeBeanRes.daftarOpenFrom) }}
            </span>
          <span class="grey--text caption">SD</span>
          <span class="blue--text">
              {{ computedDateFormattedDatefns(item.fperiodeBeanRes.daftarCloseTo) }}
          </span>

          <v-flex class="d-flex justify-end" v-if="isSudahTutupPendaftaran(item.fperiodeBeanRes)">
            <v-btn rounded color="red lighten-4"  class="mt-4 mx-0" >
              Maaf Sudah Tutup
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="d-flex justify-end" v-else-if="!isSudahBukaPendaftaran(item.fperiodeBeanRes)">
            <v-btn rounded color="grey" dark class="mt-4 mx-0" >
              Belum Buka
              <v-icon small>mdi-flag-checkered</v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="d-flex justify-end" v-else>
            <v-btn rounded color="primary" dark class="mt-4 mx-0" :to="`ppdb-market-item/` + linkNeated(item.id.toString())">
              Daftar Sekarang
              <v-icon small>mdi-walk</v-icon>
            </v-btn>
          </v-flex>

        </v-card-text>

      </v-card>

    </v-layout>

    <v-container>
      <v-row justify="center" align="center">

        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>


import FKurikulumService from '../../../services/apiservices/f-kurikulum-service'
import FileService from "@/services/apiservices/file-service"

import {format, parseISO} from "date-fns";
import FtKrsService from "@/services/apiservices/ft-krs-service";

export default {
  components: {

  },
  data () {
    return {
      snackbar: false,
      snackBarMesage: '',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 9,
      pageSizes: [9, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'coverImage',
          width: '20%'
        },
        { text: '', value: 'title', width:'30%' },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fKurikulums:[],
      itemsFtKrs:[],
      itemsFDivision: [],
      itemsFPeriode: [],
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchData()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchData()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fKurikulumFiltered(){
        let dataModified =[]
        for (let i=0; i<this.fKurikulums.length; i++){
          let itemData = this.fKurikulums[i];
          if (itemData.contentBody !==undefined){
            if (itemData.contentBody.length>100){
              itemData.contentBody = itemData.contentBody.substr(0, 99)
            }
          }
          //Tambahan khusus untuk dahlanikhsan
          if (itemData.fdivisionBean !== 7){
            dataModified.push(itemData)
          }

        }

        return dataModified.filter( x => x.statusActive===true);

      // return this.ftKrses
    },

  },
  methods: {
    isSudahBukaPendaftaran(value){
      let sudahBuka = false
      const now = new Date();
      if (now >= new Date(value.daftarOpenFrom) && now <= new Date(value.daftarCloseTo)){
        sudahBuka = true
      }

      return sudahBuka
    },
    isSudahTutupPendaftaran(value){
      let sudahTutup = false
      const now = new Date();
      if (now >= new Date(value.daftarCloseTo)){
        sudahTutup = true
      }

      return sudahTutup

    },
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchData()
      }
    },
    fetchParent(){

    },

    fetchData() {
      FKurikulumService.getAllFKurikulumContainingPublic(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {

            const { items, totalPages} = response.data
            this.fKurikulums = items
            this.totalPaginationPages = totalPages


            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)

            const fkurikulumIds = []
            items.forEach( item => {

                  console.log(JSON.stringify(item.id))

                  const id =JSON.stringify(item.id)
                  fkurikulumIds.push(id)

                }
            )
            FtKrsService.getAllFtKrsByFkurikulumsPublic(fkurikulumIds).then(
                response=>{
                  this.itemsFtKrs = response.data

                },
                error =>{
                  console.log(error)
                }
            )


          },
          error => {
            console.log(error.response)
            if (error.response.status === 401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    lookupFPeriode (fperiodeBean) {

      const str = this.itemsFPeriode.filter(x => x.id===fperiodeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }

    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/mengaji_1.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/mengaji_1.jpeg'
    },
    lookupFtKrs (fkurikulumBean, laki) {
      return  this.itemsFtKrs.filter(x => x.fkurikulumBean === fkurikulumBean && x.fsiswaRes.sex ===laki && x.enumStatApproval==='APPROVE')
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    dateTimeFormat (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy hh:mm:ss') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {

    this.fetchParent()
    this.fetchData()

  }

}
</script>

<style scoped>

</style>